<script setup lang="ts">
const { isSuperadmin } = useUserState()
const db = useFirestore()
const insights = useInsightsList(db)

// if isSuperadmin or refCompany has a bucket or refCompany status is demo
const hasInsights = computed(() => {
  return isSuperadmin || (!isSuperadmin && insights.value.length > 0)
})
</script>

<template>
  <v-list>
    <v-divider />
    <v-list-item title="Briefings" prepend-icon="$mdiListbox" to="/briefings" />
    <v-divider />
    <v-list-item title="Productos" prepend-icon="$mdiDashboard" to="/products" />
    <v-divider v-if="isSuperadmin" />
    <v-list-item v-if="isSuperadmin" title="SaaS Clientes" prepend-icon="$mdiOffice" to="/saas-clientes" />
    <v-divider v-if="hasInsights" />
    <v-list-item v-if="hasInsights" title="Recomendaciones" prepend-icon="$mdiLightbulbOnOutline" to="/insights" />
    <v-divider />
    <v-list-item v-if="isSuperadmin" title="Analytics" prepend-icon="$mdiAnalytics" to="/analytics" />
    <v-divider v-if="isSuperadmin" />
  </v-list>
</template>
